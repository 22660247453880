

import CS1 from "./../../images/products/colorsorter/1.png";


//
const colorSorterImages = [
    { original: CS1, thumbnail: CS1 }
];

const colorSorterBase = {
        id: 'color-sorter',
        title: 'Color Sorter',
        series: 'Color Sorter',
        images: colorSorterImages,
        description: 'At Sona Machinery Ltd., we are committed to revolutionizing the grain processing industry with cutting-edge technology. Our newest addition, the Sona Color Sorter, is poised to transform the way you sort grains, ensuring unparalleled efficiency and quality in every batch.',
        usp: ['Precision Sorting', 'Enhanced Efficiency', 'Customizable Settings', 'Reliability And Durability'],
        specification: [{ 'Product Name': 'Color Sorter' },],
        category: 'Color Sorter',
        categoryLink: '/color-sorter'
}
const colorsorters = [
    {
        ...colorSorterBase,
        id: 'color-sorter-g1',
        title: 'Color Sorter G1',
        specification: [{ 'Model': 'G1' }, {'Ejector Count': 64}, {'Throughput (T/H)': '0.9-1.5'} , {'Typical Air Req': '>=18'},{'PowerConsumption(kW)': '<=1.5'} , {'NetWt (Kg)': '350(+-5%)'} ,{'Dimension(LxWxH)(mm)': '955*1360*1550'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g2',
        title: 'Color Sorter G2',
        specification: [{ 'Model': 'G2' }, {'Ejector Count': 128}, {'Throughput (T/H)': '1.5-3.5'} , {'Typical Air Req': '>=36'},{'PowerConsumption(kW)': '<=2.5'} , {'NetWt (Kg)': '570(+-5%)'} ,{'Dimension(LxWxH)(mm)': '1330*1630*1550'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g3',
        title: 'Color Sorter G3',
        specification: [{ 'Model': 'G3' }, {'Ejector Count': 192}, {'Throughput (T/H)': '2.0-5.0'} , {'Typical Air Req': '>=54'},{'PowerConsumption(kW)': '<=3.0'} , {'NetWt (Kg)': '820(+-5%)'} ,{'Dimension(LxWxH)(mm)': '1717*1619*2042'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g4',
        title: 'Color Sorter G4',
        specification: [{ 'Model': 'G4' }, {'Ejector Count': 256}, {'Throughput (T/H)': '3.0-6.0'} , {'Typical Air Req': '>=72'},{'PowerConsumption(kW)': '<=4'} , {'NetWt (Kg)': '1121(+-5%)'} ,{'Dimension(LxWxH)(mm)': '2030*1619*2042'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g5',
        title: 'Color Sorter G5',
        specification: [{ 'Model': 'G5' }, {'Ejector Count': 320}, {'Throughput (T/H)': '4.0-7.0'} , {'Typical Air Req': '>=90'},{'PowerConsumption(kW)': '<=4.8'} , {'NetWt (Kg)': '1135(+-5%)'} ,{'Dimension(LxWxH)(mm)': '2343*1619*2042'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g6',
        title: 'Color Sorter G6',
        specification: [{ 'Model': 'G6' }, {'Ejector Count': 384}, {'Throughput (T/H)': '5.0-8.0'} , {'Typical Air Req': '>=108'},{'PowerConsumption(kW)': '<=5.2'} , {'NetWt (Kg)': '1248(+-5%)'} ,{'Dimension(LxWxH)(mm)': '2656*1619*2042'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g7',
        title: 'Color Sorter G7',
        specification: [{ 'Model': 'G7' }, {'Ejector Count': 448}, {'Throughput (T/H)': '6.0-10.0'} , {'Typical Air Req': '>=126'},{'PowerConsumption(kW)': '<=5.8'} , {'NetWt (Kg)': '1539(+-5%)'} ,{'Dimension(LxWxH)(mm)': '3149*1620*2032'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g8',
        title: 'Color Sorter G8',
        specification: [{ 'Model': 'G8' }, {'Ejector Count': 512}, {'Throughput (T/H)': '7.0-11.0'} , {'Typical Air Req': '>=144'},{'PowerConsumption(kW)': '<=6.5'} , {'NetWt (Kg)': '1852(+-5%)'} ,{'Dimension(LxWxH)(mm)': '3462*1620*2032'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g10',
        title: 'Color Sorter G10',
        specification: [{ 'Model': 'G10' }, {'Ejector Count': 640}, {'Throughput (T/H)': '8.0-15.0'} , {'Typical Air Req': '>=180'},{'PowerConsumption(kW)': '<=8'} , {'NetWt (Kg)': '2335(+-5%)'} ,{'Dimension(LxWxH)(mm)': '4128*1618*2032'}],
    },{
        ...colorSorterBase,
        id: 'color-sorter-g12',
        title: 'Color Sorter G12',
        specification: [{ 'Model': 'G12' }, {'Ejector Count': 768}, {'Throughput (T/H)': '10.0-19.0'} , {'Typical Air Req': '>=218'},{'PowerConsumption(kW)': '<=11'} , {'NetWt (Kg)': '2670(+-5%)'} ,{'Dimension(LxWxH)(mm)': '4698*1618*2032'}],
    }
   
]

export default colorsorters;