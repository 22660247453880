import React from 'react';
import SORTER from '../../../images/Gradingg.png';
import SORTER_IMG from '../../../images/products/colorsorter/1.png';

import PageHeader from "../../global/pageheader";
import ProductTile from '../productTile';

export default function ColorSorter() {

  const sorters = [
    {
      id: 1,
      img: SORTER_IMG,
      title: 'Color Sorter',
      link: '/color-sorter-g1'
    },

  ]

  return (
    <div className="container-fluid">
      <PageHeader img={SORTER} head="Color Sorter"></PageHeader>
      <div className="container">
        <div className="row">

          {
            sorters.map((item, i) => {
              return ProductTile({
                i,
                link: item.link,
                title: item.title,
                img: item.img
              })
            })
          }
        </div>
        <div className="my-5">
          <div className="row">
            <h2 className=" fw-bold">Precision Sorting</h2>
            <p className="">Our color sorter utilizes advanced technology to accurately sort grains based on color, ensuring only the highest quality products make it to the next stage of processing.</p>
          </div>

          <div className="row">
            <h2 className=" fw-bold">Enhanced Efficiency</h2>
            <p className="">With high-speed sorting capabilities, the Sona Color Sorter significantly reduces sorting time, allowing you to streamline your operations and maximize productivity</p>
          </div>

          <div className="row">
            <h2 className="fw-bold">Customizable Settings</h2>
            <p >Tailor the sorting parameters to meet your specific requirements, whether you're sorting rice, grains, or seeds, ensuring optimal results every time.</p>
          </div>

          <div className="row">
            <h2 className="fw-bold">Reliability and Durability</h2>
            <p >Built with robust materials and designed for long-term performance, the Sona Color Sorter is engineered to withstand the rigors of continuous use in demanding industrial environments.</p>
          </div>
        </div>
      </div>
    </div>
  )
}
