import blenders from './blender';
import finecleaners from './finecleaners';
import floursifter from './floursifter';
import huskfan from './huskfan';
import lengthgrader from './lengthgrader';
import beltconveyor from './materialhandling/beltconveyor'
import bucketeleveator from './materialhandling/bucketelevator';
import chainconveyor from './materialhandling/chainconveyer';
import screwconveyor from './materialhandling/screwconveyor';
import multigrader from './multigrader';
import paddyseparators from './paddyseparators';
import sizer from './sizer';
import stoneseparators from './stoneseparators';
import precleaners from "./precleaners";
import ricewhitener from './ricewhitener';
import silkypolisher from './silkypolisher';
import paddyhuskers from './paddyhuskers';
import colorsorter from './colorsorter';

let productData = []

productData = productData.concat(beltconveyor).concat(chainconveyor);
productData = productData.concat(bucketeleveator).concat(screwconveyor);

productData = productData.concat(blenders).concat(floursifter);

productData = productData.concat(stoneseparators).concat(finecleaners);

productData = productData.concat(floursifter).concat(sizer);

productData = productData.concat(multigrader).concat(lengthgrader);

productData = productData.concat(huskfan).concat(paddyseparators);

productData = productData.concat(precleaners).concat(ricewhitener);

productData = productData.concat(silkypolisher).concat(paddyhuskers);

productData = productData.concat(colorsorter);

export default productData;