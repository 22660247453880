const menuItemList = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About Us',
    submenu: [
      {
        title: 'About',
        url: '/about-us'
      },
      {
        title: 'Vision & Mission',
        url: '/vision-and-mission'
      },
      {
        title: 'Management',
        url: '/management-team'
      }
    ]
  },
  {
    title: 'Products',
    submenu: [
      {
        title: 'Turnkey Ricemill Solutions',
        url: '/turnkey-rice-mill-solutions'
      },
      {
        title: 'Grain Based Distilleries',
        url: '/epc-solutions-for-grain-unloading-and-milling'
      },
      {
        title: 'Cleaning',
        submenu: [
          {
            title: 'Pre Cleaners',
            url: '/pre-cleaner'
          },
          {

            title: 'Fine Cleaners',
            url: '/fine-cleaner'
          },
          {
            title: 'Stone Separators',
            url: '/stone-separator'
          }
        ]
      },
      {
        title: 'Milling',
        submenu: [
          {
            title: 'Paddy Husker',
            url: '/paddy-husker'
          },
          {

            title: 'Husk Fan',
            url: '/husk-fan'
          },
          {
            title: 'Paddy Separators',
            url: '/paddy-separators'
          },
          {
            title: 'Rice Whitener',
            url: '/rice-whitener'
          },
          {
            title: 'Silky Polisher',
            url: '/silky-polisher'
          }
        ]
      },
      {
        title: 'Grading',
        submenu: [
          {
            title: 'Color Sorter',
            url: '/color-sorter'
          },
          {
            title: 'Sizer',
            url: '/sizer'
          },
          {

            title: 'Multi Grader',
            url: '/multi-grader'
          },
          {
            title: 'Length Grader',
            url: '/length-grader'
          }
        ]
      },
      {
        title: 'Blending',
        url: '/products/blending-RKB30A',
      },
      {
        title: 'Material Handling',
        submenu: [
          {
            title: 'Bucket Elevator',
            url: '/material-handling/rice-mill-bucket-elevator'
          },
          {
            title: 'Belt Conveyor',
            url: '/material-handling/belt-conveyor'
          },
          {
            title: 'Chain Conveyor',
            url: '/material-handling/chain-conveyor'
          },
          {
            title: 'Screw Conveyor',
            url: '/material-handling/screw-conveyor'
          },
        ]
      },
      {
        title: 'Flour Sifter',
        url: '/products/flour-sifter'
      }
    ]
  },
  {
    title: 'Investors',
    submenu: [
      {
        title: 'Investors',
        url: '/investors'
      },
      {
        title: 'Board Of Directors',
        url: '/investors/board-composition'
      },
      {
        title: 'Contact Us',
        url: '/investors/investor-contact'
      },
      {
        title: 'Grievances',
        url: '/investors/investor-grievance'
      },
    ]
  },
  {
    title: 'News & Events',
    submenu: [
      {
        title: 'Events',
        submenu: [
          {
            title: 'Past Events',
            url: '/events/past-events'
          },
          {
            title: 'Upcoming Events',
            url: '/events/upcoming-events'
          }
        ]
      },
      {
        title: 'News',
        submenu: [
          {
            title: '2024',
            url: '/news/2024'
          },
          {
            title: '2023',
            url: '/news/2023'
          },
          {
            title: '2022',
            url: '/news/2022'
          },
          {
            title: '2021',
            url: '/news/2021'
          }
        ]
      }
    ]
  },
  {
    title: 'Careers',
    url: '/careers'
  },
  {
    title: 'Blogs',
    url: '/blogs'
  },
  {
    title: 'Contact Us',
    url: '/contact'
  }
];

export default menuItemList;